<template>
    <v-dialog v-model="visible" overlay-opacity="0.3" fullscreen transition="fade-in">
        <v-card width="100%" height="100%" color="rgba(0,0,0,0.4)" >
            <v-layout fill-height justify-center align-center>
                <v-card height="140px" width="140px" color="rgba(0,0,0,0.5)" 
                    style="display:flex;align-items:center;justify-content:center;border-radius:100px">
                    <v-img :src="require('@/assets/logo.png')" width="90" style="position:absolute"/>
                    <v-progress-circular
                        :size="120"
                        :width="7"
                        color="yellow"
                        indeterminate
                    ></v-progress-circular>
                </v-card>
            </v-layout>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        visible:{
            type: Boolean,
            required:true,
        }
    },
    data(){
        return{
            dialog:false
        }
    },
    watch:{
        visible(){
            this.dialog = this.visible;
        }
    },
    computed:{
    }
}
</script>

<style>

</style>