import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        load: false,
        snackbar:{},
        dadosEmpresa:{}
    },
    mutations: {
        showLoad(state, load){
            state.load = load;
        },
        setSnackbar(state,snackbar){
            state.snackbar = snackbar;
        },
        setDadosEmpresa(state, dados){
            state.dadosEmpresa = dados;
        }
    },
    actions:{
        setSnackbar(context,snackbar){
            context.commit("setSnackbar",snackbar);
        },
        setDadosEmpresa(context,dados){
            context.commit("setDadosEmpresa",dados);
        },
        setShowLoad(context,load){
            context.commit("showLoad", load);
        }
    },
    getters:{
        showLoad: state => {
            return state.load
        },
        snackbar: state => {
            return state.snackbar
        },
        dadosEmpresa: state => {
            return state.dadosEmpresa
        }
    }
})