<template>
    <v-snackbar
        :top="center" 
        centered
        :color="color"
        :value="visible"
        :timeout="4000"
    >
      {{ text }}
    </v-snackbar>
</template>

<script>
export default {
    watch:{
        visible(){
            if(this.visible==true){
                setTimeout(() => {
                    this.$store.dispatch("setSnackbar",{
                        visible:false,
                        text: "",
                        color: ""
                    })
                }, 4000);
            }
        }
    },
    computed:{
        snackbar(){
            if(!!this.$store.getters["snackbar"]){
                return this.$store.getters["snackbar"];
            }else{
                return {
                    visible:false,
                    text: "",
                    color: ""
                }
            }
        },
        center(){
            return this.snackbar.center==true?false:true;
        },
        visible(){
            return !!this.snackbar.visible?this.snackbar.visible:false;
        },
        color(){
            return !!this.snackbar.color?this.snackbar.color:"";
        },
        text(){
            return !!this.snackbar.text?this.snackbar.text:"";
        }
    }
}
</script>

<style>

</style>