<template>
    <v-app-bar app color="#212121" dark>
		<div class="d-flex align-center justify-center px-auto mx-auto" style="width:250px">
			<v-img :src="require('@/assets/nameLogo.png')" style="height:50px;margin:auto" contain/>
		</div>
    </v-app-bar>
</template>

<script>
export default {
	
}
</script>

<style>

</style>