<template>
  <v-container style="max-width:960px">
    <h1>Google My Business Offline Access Demo</h1>
    <p> This demo demonstrates the use of using Google Identity Services and OAuth to gain authorization to call the Google My Business API on behalf of the user, even when the user is offline.
    When a refresh token is acquired, store this token securely on your database. You will then be able to use this token to refresh the OAuth credentials and make offline API calls on behalf of the user.
    The user may revoke access at any time from the <a href='https://myaccount.google.com/permissions'>Account Settings</a> page.</p>
    <div>
      <v-btn color="#4688f1" height="50" class="white--text" @click="onAuth" ref="refGoogle" :disabled="acessado==true">
        <v-icon class="mr-5" color="white">mdi-google</v-icon>Acessar com Google
      </v-btn>
      <v-layout class="mt-3">
        <v-text-field outlined readonly label="Cógido de Autorização" v-model="authorizationCode" />
        <v-btn color="#212121" height="55" class="ml-2 white--text" @click="onRecoveryAccess" :disabled="acessado==false">
          Recuperar acesso / atualizar token
        </v-btn>
      </v-layout>
      <v-layout>
        <v-text-field outlined readonly label="Atualizar token, nunca expira a menos que seja revogado" v-model="token" />
        <v-btn color="#212121" height="55" class="ml-2 white--text" @click="onRefreshToken" :disabled="!!token==false">
          Atualizar Token de Acesso
        </v-btn>
      </v-layout>
      <v-layout>
        <v-text-field outlined readonly label="Token de Acesso" v-model="token2"/>
        <v-btn color="#212121" height="55" class="ml-2 white--text" @click="onAccounts"  :disabled="!!token2==false">
          Obtenha Contas do Google My Business
        </v-btn>
      </v-layout>
    </div>
     <v-dialog v-model="showLoad" hide-overlay persistent fullscreen transition="none">
       <v-layout justify-center align-center fill-height>
          <v-card color="primary" dark width="300" height="60"> 
            <v-card-text>
              Carregando...
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
       </v-layout>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    showLoad:false,
    acessado:false,
    authorizationCode:"",
    token:"",
    token2:""
  }),
  created(){
    console.log(this.$route);
    console.log(location.href);
    console.log(location.origin)
  },
  methods:{
    async onAuth(){
      // let api = "http://localhost:3040/api/rest/tauth/gerarLogin";
      // let {status, data} = await axios.get(api,{
      //   auth: {
      //     username: 'beetech',
      //     password: '1q2w3e4r'
      //   }
      // });
      // console.log(status);
      // if(status == 200){
      //   let wind = window.open(data.url, "_blank", "height=800, width=550, status=yes, toolbar=no, menubar=no, location=no,addressbar=no"); 
      //   console.log(wind);
      //   wind.opener
      //   wind.onload = function () {
      //     console.log(wind);
      //     wind.onpopstate = function (e) {
      //         console.log('pop', e);
      //     };
      //     wind.onclose = function (e) {
      //         console.log('close', e);
      //     };
      //     wind.onchange = function (e) {
      //         console.log('cahnge', e);
      //     };
      //   };
      // }
      auth2.grantOfflineAccess().then(this.signInCallback).catch(err=>{
        console.log('err',err);
      });
    },
    onRecoveryAccess(){
      this.retrieveAccessTokenAndRefreshToken(this.authorizationCode);
    },
    onRefreshToken(){
      this.retrieveAccessTokenFromRefreshToken(this.token);
    },
    onAccounts(){
      this.retrieveGoogleMyBusinessAccounts(this.token2);
    },
    signInCallback(authResult) {
      console.log(authResult);
      if (authResult['code']) {
        this.authorizationCode = authResult['code'];
        this.acessado = true;

        var e = document.createElement("pre")
        e.innerHTML = JSON.stringify(authResult, undefined, 2);
        document.body.appendChild(e);
      } else {
        // There was an error.
      }
    },
    async retrieveAccessTokenAndRefreshToken(code) {
      this.showLoad=true;
      console.log(code);
      let obj = { 
        'code' : code,
        'client_id' : '330954761889-5unm0livavf3v4hdr5ef2iimbsop0j5u.apps.googleusercontent.com',
        'client_secret' : "-YcI51ECcgUL2KTr7t024gI_",
        'redirect_uri' : location.origin,
        'grant_type' : 'authorization_code'
      };
      await axios.post('https://www.googleapis.com/oauth2/v4/token',obj).then(response=>{
        console.log(response.data);
        var e = document.createElement("pre")
        e.innerHTML = JSON.stringify(response.data, undefined, 2);
        document.body.appendChild(e);
        this.token = response.data.refresh_token;
      });
      this.showLoad=false;
    },
    async retrieveAccessTokenFromRefreshToken(refreshToken) {
      this.showLoad=true;
      console.log(refreshToken);
      let obj = { 
        'refresh_token' : refreshToken,
        'client_id' : '330954761889-5unm0livavf3v4hdr5ef2iimbsop0j5u.apps.googleusercontent.com',
        'client_secret' : "-YcI51ECcgUL2KTr7t024gI_",
        'redirect_uri' : location.origin,
        'grant_type' : 'refresh_token'
      };
      await axios.post('https://www.googleapis.com/oauth2/v4/token',obj).then(response=>{
        console.log(response.data);
        var e = document.createElement("pre")
        e.innerHTML = JSON.stringify(response.data, undefined, 2);
        document.body.appendChild(e);
        this.token2 = response.data['access_token'];
      });
      this.showLoad=false;
    },
    async retrieveGoogleMyBusinessAccounts(accessToken) {
      this.showLoad=true;
      console.log(accessToken);
      await axios.get('https://mybusinessaccountmanagement.googleapis.com/v1/accounts',{
        headers: {
          'Authorization' : 'Bearer ' + accessToken
        }
      }).then(response=>{
        console.log(response.data);
        var e = document.createElement("pre")
        e.innerHTML = JSON.stringify(response.data, undefined, 2);
        document.body.appendChild(e);
      });
      this.showLoad=false;
    }
  }
}
</script>
