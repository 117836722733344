<template>
	<v-app>
		<Nav/>
		<Snackbar/>
		<Load :visible="$store.state.load"/>
		<v-main>
		<router-view></router-view>
		</v-main>
	</v-app>
</template>


<script>
import router from "@/router";
import Load from "@/components/UI/Load.vue";
import Nav from  "@/components/Navigation/Menu.vue";
import Snackbar from "@/components/UI/Snackbar.vue";
export default {
	components:{
		Nav,
		Load,
		Snackbar
	}
};
</script>
