import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/pages/index.vue';
Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/business/:empresaID/:filialID/:linkAcesso',
      name: 'business',
      component: () => import('@/pages/business.vue')
    },
    {
      path: '/error',
      name: 'error',
      component: () => import('@/pages/error.vue')
    },
    { path: "*",  component: () => import('@/pages/error.vue') }
  ],
});
export default router;